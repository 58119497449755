import * as React from "react";

const PopUpSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    fill="none"
    viewBox="0 0 56 56"
  >
    <path
      fill="#fff"
      d="M25.25 41.75h5.5v-16.5h-5.5zM28 .5C12.806.5.5 12.806.5 28S12.806 55.5 28 55.5 55.5 43.194 55.5 28 43.194.5 28 .5M28 50C15.873 50 6 40.127 6 28S15.873 6 28 6s22 9.873 22 22-9.873 22-22 22m-2.75-30.25h5.5v-5.5h-5.5z"
    ></path>
  </svg>
);

export default PopUpSvg;

import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import PopUpSvg from "./PopUpSvg";

const FeaturePopup = () => {
  const [isVisible, setIsVisible] = useState(true);

  // Check if the popup has been shown before

  // Handle closing the popup
  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <PopupContainer>
        <PopupContent>
          <PopupText>
            <IconContainer>
              <PopUpSvg />
            </IconContainer>
            <strong>
              You can now access the latest feature at{" "}
              <a
                href="https://app.camzify.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                CAMZIFY
              </a>{" "}
              <br />
              The current version will not be available from{" "}
              <span>Jan 1st, 2025.</span>
            </strong>
          </PopupText>
          <CloseButton onClick={handleClose}>x</CloseButton>
        </PopupContent>
      </PopupContainer>
    </div>
  );
};

export default FeaturePopup;

const slideDownBounce = keyframes`
  0% {
    transform: translateY(-30px); 
    opacity: 0;
  }
  30% {
    transform: translateY(10px); 
  }
  50% {
    transform: translateY(5px); 
  }
  75% {
    transform: translateY(10px); 
  }
  100% {
    transform: translateY(0); 
    opacity: 1;
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 20px;
  width: 100%;
  max-width: 800px;
  background-color: #ed592b;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1000;
  animation: ${slideDownBounce} 0.7s ease-out;
  transition: all 0.5s ease;

  /* Hover effect for large and extra-large devices */
  @media (min-width: 1024px) {
    &:hover {
      background: linear-gradient(
        to right,
        #bf360c,
        #ed592b
      ); /* Gradient from left to right */
      max-width: 850px;
      padding: 10px 20px;
    }
  }

  @media (min-width: 1024px) {
    &:hover {
      max-width: 850px;
      padding: 10px 20px;
    }
  }

  a {
    transition: all 0.5s ease;
  }

  @media (max-width: 768px) {
    max-width: 95%;
  }

  @media (max-width: 480px) {
    max-width: 90%;
    border-radius: 6px;
  }

  @media (max-width: 300px) {
    max-width: 95%;
    border-radius: 4px;
    padding: 0.5rem;
  }
`;

const PopupContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;

  @media (max-width: 700px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }

  @media (max-width: 300px) {
    padding: 0.8rem;
    gap: 8px;
  }

  &:hover {
    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  &:hover span {
    font-weight: bold;
  }
`;

const PopupText = styled.p`
  font-size: 1rem;
  color: #333;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 13px;
  font-family: "Poppins", sans-serif;
  /* border: 1px solid yellow; */
  position: relative;
  font-size: 20px;

  strong {
    color: white;
    font-weight: 400;

    @media (max-width: 700px) {
      font-size: 1rem;
    }

    @media (max-width: 515px) {
      font-size: 0.7rem;
    }

    @media (max-width: 375px) {
      font-size: 0.5rem;
      line-height: 1.2;
    }

    a {
      cursor: pointer;
      /* font-weight: bold; */
      color: white;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }

  @media (max-width: 300px) {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }
`;

const CloseButton = styled.button`
  /* position: absolute; */
  background: none;
  border: none;
  font-size: 2.5rem;
  color: white;
  cursor: pointer;
  /* font-weight: bold; */
  transition: color 0.2s;

  padding: 0px;

  &:hover {
    color: white;
  }

  /* Pseudo-element only visible on large and extra-large screens */
  @media (min-width: 1024px) {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 50px;
      width: 1px;
      background-color: #ffffff80;
      transform: translateX(-10px); /* Adjust spacing from the text */
    }
  }

  position: relative;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }

  @media (max-width: 300px) {
    font-size: 1rem;
    top: 6px;
    right: 6px;
  }
`;

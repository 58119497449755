import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SizedBox } from "../../../../../../../helpers/utils/SizedBox";
import Select from "react-select";
import Spinner from "../../../../../../Spinner";
import { VPSContext } from "../../../MainWrapper";
import { APIUtils } from "../../../../../../../helpers/utils/api";
import APIEndpoints from "../../../../../../../config/api/endpoints";
import displayError from "../../../../../../../helpers/displayError";
import axiosInstance from "../../../../../../../config/axios";

const Wrapper = styled.div`
  h5 {
    margin-top: 0;
    padding-bottom: 0.5rem;
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-weight: 700;
    color: ${(props) =>
      props.theme === "dark"
        ? "rgba(94, 132, 195, 1)"
        : "rgba(37, 68, 120, 1)"};
  }

  .add-camera_btn {
    padding: 0.4rem 1.2rem;
  }
`;

export const AddCameraSequence = ({ theme }) => {
  const [data, setData] = useState({});
  const [cameraList, setCameraList] = useState([]);
  const [formattedCameraList, setFormattedCameraList] = useState([]);
  const [isDropdownOpen, setIsDropDownOpened] = useState(false);
  const [isAddingSequence, setIsAddingSequence] = useState(false);
  const fetchTimeout = useRef(null);
  const { fetchVPSCameraSequenceInstance } = useContext(VPSContext);

  const handleOnSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!data?.stream) {
        displayError("Select a camera to add", theme);
      }
      const requestData = {
        stream: data?.stream,
        sequence_name: data?.label,
      };
      setIsAddingSequence(true);
      await (
        await axiosInstance()
      )
        .post(APIEndpoints.instance.vps.streamSequence.add(), requestData)
        .then((response) => {
          fetchVPSCameraSequenceInstance();
          setData({});
          APIUtils.fetchItemData({
            endpoint:
              APIEndpoints.instance.vps.streamSequence.fetchAllStreamsAvailable(),
            item: cameraList,
            setItem: setCameraList,
          });
        })
        .catch((err) => {
          displayError(err, theme);
        });
      setIsAddingSequence(false);
    },
    [fetchVPSCameraSequenceInstance, cameraList, isAddingSequence, data]
  );

  useEffect(() => {
    APIUtils.fetchItemData({
      endpoint:
        APIEndpoints.instance.vps.streamSequence.fetchAllStreamsAvailable(),
      item: cameraList,
      setItem: setCameraList,
    });
  }, []);

  useEffect(() => {
    cameraList?.isFetching === false &&
      setFormattedCameraList(
        (cameraList?.data || []).map((item) => ({
          value: item?.id,
          label: item?.label_name,
        }))
      );
  }, [cameraList]);

  const customStyles = (theme) => {
    return {
      container: (provided) => ({
        ...provided,
        width: "100%",
      }),
      control: (provided, state) => ({
        ...provided,
        outline: "none",

        backgroundColor: theme === "dark" ? "rgba(38, 56, 84, 1)" : "white",
        color: theme === "dark" ? "white" : "black",
        border: "1.5px solid rgba(27,68,120,1)",
        borderRadius: "27px",
        boxShadow: state.isFocused ? null : provided.boxShadow,
        display: "flex",
        alignItems: "center",
      }),
      option: (provided) => ({
        ...provided,
        outline: "none",
        backgroundColor: theme === "dark" ? "rgba(38, 56, 84, 1)" : "white",
        color: theme === "dark" ? "white" : "black",
        cursor: "pointer",
      }),

      placeholder: (provided) => ({
        ...provided,
        color: theme === "dark" ? "white" : "black",
      }),
      input: (provided) => ({
        ...provided,
        boxShadow: "none",
      }),
      noOptionsMessage: () => ({
        color: theme === "dark" ? "white" : "black",
        fontSize: "16px",
        padding: "0.6rem",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: theme === "dark" ? "white" : "black",
        border: "none",
      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        color: "rgba(37, 68,120,1)",
        cursor: "pointer",
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),

      clearIndicator: () => ({
        color: theme === "dark" ? "white" : "black",
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: theme === "dark" ? "rgba(38, 56, 84, 1)" : "white", // Change the background color of the dropdown menu
        color: theme === "dark" ? "white" : "black",
        borderRadius: "15px",
        border: "none",
        boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.25)",
      }),

      menuList: (provided) => ({
        ...provided,
        backgroundColor: theme === "dark" ? "rgba(38, 56, 84, 1)" : "white", // Change the background color of the dropdown menu
        color: theme === "dark" ? "white" : "black",
        border: "none",
        borderRadius: "15px",
        border: "none",
        boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.25)",
      }),
    };
  };

  return (
    <Wrapper theme={theme}>
      <h5>Add Camera Sequence</h5>
      <Select
        onChange={(e) => {
          setData({
            stream: e.value,
            label: e.label,
          });
        }}
        styles={customStyles(theme)}
        onMenuOpen={(e) => {
          setIsDropDownOpened(true);
        }}
        onMenuClose={(e) => {
          setIsDropDownOpened(false);
        }}
        onKeyDown={(e) => {
          setTimeout(() => {
            if (fetchTimeout.current) {
              clearTimeout(fetchTimeout.current);
            }
            fetchTimeout.current = setTimeout(() => {
              APIUtils.fetchItemData({
                endpoint:
                  APIEndpoints.instance.vps.streamSequence.fetchAllStreamsAvailable(
                    {
                      search: e.target.value,
                    }
                  ),
                item: cameraList,
                setItem: setCameraList,
              });
            }, 500);
          }, 150);
        }}
        options={formattedCameraList}
        menuShouldBlockScroll={true}
        captureMenuScroll={true}
        maxMenuHeight={"10rem"}
        isLoading={cameraList?.isFetching}
        placeholder="Select Camera"
      />
      <SizedBox height={1} />
      {!isDropdownOpen &&
        (isAddingSequence ? (
          <Spinner size={1.5} />
        ) : (
          <Link
            className={`orange-text add-camera_btn ${
              theme === "dark" && "add-camera_btn-dark"
            }`}
            to=""
            onClick={handleOnSubmit}
          >
            ADD
          </Link>
        ))}
    </Wrapper>
  );
};

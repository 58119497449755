import { BrowserRouter, HashRouter } from "react-router-dom";
import "./styles/forms.css";
import "./styles/buttons.css";
import "./styles/text.css";
import "./styles/spinner.css";
import "./styles/db_display_grid.css";
import "./styles/alert.css";
import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "./config/axios";
import { GlobalRoutes } from "./routes/GlobalRoutes";
import { AdminRoutes } from "./routes/AdminRoutes";
import { SubAdminRoutes } from "./routes/SubAdminRoutes";
import { SubAdminSubUserRoutes } from "./routes/SubAdmin&SubUserRoutes";
import APIEndpoints from "./config/api/endpoints";
import displayError from "./helpers/displayError";
import { AppConfig } from "./config";
import { APPProductionEnvironmentType } from "./config/enums/system";
import { ParentIdentities } from "./config/enums/auth/relation";
import FeaturePopup from "./popup/FeaturePopup";

export const GlobalUserProfileContext = createContext({
  isFetchingProfile: null,
  profile: null,
});

const App = () => {
  const { theme } = useSelector((state) => state.themeReducer);
  const {
    isFetching: isFetchingProfile,
    profile,
    lastTimeFetched,
  } = useSelector((state) => state.profileReducer);
  const [parentProfile, setParentProfile] = useState({});

  useEffect(() => {
    const fetchParentProfiles = async () => {
      const tempParentProfile = { ...parentProfile };
      for (let key of ParentIdentities.keys()) {
        if (
          Number.parseInt(window.localStorage.getItem(ParentIdentities[key])) >=
          1
        ) {
          const parentID = window.localStorage.getItem(ParentIdentities[key]);
          await (
            await axiosInstance()
          )
            .get(APIEndpoints.auth.relation.getRelation(parentID))
            .then((response) => {
              tempParentProfile[key] = response.data;
            })
            .catch((err) => {
              displayError(err, theme);
            });
        }
      }
      setParentProfile(tempParentProfile);
    };
    if (profile) {
      fetchParentProfiles();
    }
  }, [profile]);

  const Router =
    AppConfig.APP_PRODUCTION_ENVIRONMENT ===
    APPProductionEnvironmentType.DESKTOP
      ? HashRouter
      : BrowserRouter;
  return (
    <GlobalUserProfileContext.Provider
      value={{
        profile,
        isFetchingProfile,
        setParentProfile,
        parentProfile,
        lastTimeFetched,
      }}
    >
      <FeaturePopup />

      <Router>
        <GlobalRoutes.Mapping />
        <AdminRoutes.Mapping />
        <SubAdminRoutes.Mapping />
        <SubAdminSubUserRoutes.Mapping />
        {/*<Route  path="*" element={<h1>Page Not Found</h1>}/>*/}
      </Router>
    </GlobalUserProfileContext.Provider>
  );
};

export default App;
